import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Heading, Section, Wysiwyg } from 'src/components/Base'
import BaseCard from 'src/components/Cards/BaseCard'
import NewsGrid from 'src/components/NewsGrid'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import Seo from 'src/components/Seo'
import TabControl from 'src/components/TabControl'
import {
  BestPracticeCards,
  ContactPerson,
  Hero,
  Logos,
  SalesArguments,
  Testimonials,
} from 'src/sections'

const TemplateSingleBranche = ({ data }) => {
  const page = data.wpPage
  const news = data.allWpNeuigkeit.nodes
  const hero = page.atspHero
  const intro = page.atspSingleBranche.singleBrancheIntro
  const focus = page.atspSingleBranche.singleBrancheFocus
  const tabContent = page.atspSingleBranche.singleBrancheTabContent
  const services = page.atspSingleBranche.singleBrancheServices
  const salesArguments = page.atspSingleBranche.singleBrancheSalesArguments
  const bestPractices = page.atspSingleBranche.singleBrancheBestPractices
  const testimonials = page.atspSingleBranche.singleBrancheTestimonials
  const logos = page.atspSingleBranche.singleBrancheLogos
  const contactPerson = page.atspSingleBranche.singleBrancheContactPerson
  const branchColor = page.atspBranchOptions.branchColor

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero
        imageData={hero.heroImage ? hero.heroImage.localFile : undefined}
        heading={hero.heroHeading}
        branchColor={branchColor}
      />
      <Section>
        <Heading
          isCentered
          color="dark"
          as="h2"
          size={2}
          branchColor={branchColor}
        >
          {intro.heading}
        </Heading>
        <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
      </Section>
      <SectionTwoColumns
        hasBackground
        divider
        centerMobile
        contentLeft={
          <Heading as="h2" size={2} color="dark" branchColor={branchColor}>
            {focus.heading}
          </Heading>
        }
        contentRight={<Wysiwyg>{focus.wysiwyg}</Wysiwyg>}
      />

      <Section>
        <TabControl tabs={tabContent} />
      </Section>

      <SectionTwoColumns
        heading={services.heading}
        headingColor="dark"
        introText={services.intro}
        hasBackground
        centerMobile
        reorderMobile
        branchColor={branchColor}
        contentLeft={
          <BaseCard
            headerHasNoPadding
            body={<Wysiwyg>{services.wysiwyg}</Wysiwyg>}
            backgroundColor="#FFFFFF"
            hasBorder
            isStretched
          />
        }
        contentRight={
          <GatsbyImage
            image={
              services.image
                ? services.image.localFile.childImageSharp.gatsbyImageData
                : undefined
            }
            alt={services.image ? services.image.altText : undefined}
          />
        }
      />

      <SalesArguments
        heading={salesArguments.heading}
        branchColor={branchColor}
        intro={salesArguments.intro}
        salesArgumentsRepeater={salesArguments.repeater}
      />

      <NewsGrid
        heading={page.atspSingleBranche.singleBrancheNews.heading}
        branchColor={branchColor}
        hasBackground
        posts={news}
        postsPerPage={3}
        maxPosts={3}
        hasPagination={false}
      />

      {/* for some reason there were always some empty cards in the repeater, so I decided to only show this section when a heading is in place */}
      {bestPractices.heading && (
        <BestPracticeCards
          button={bestPractices.button}
          branchColor={branchColor}
          heading={bestPractices.heading}
          intro={bestPractices.intro}
          cardsArray={bestPractices.repeater}
        />
      )}

      {testimonials.repeater && (
        <Testimonials
          hasBackground
          testimonialRepeater={testimonials.repeater}
        />
      )}

      <Logos
        heading={logos.heading}
        logos={logos.repeater}
        branchColor={branchColor}
      />
      {contactPerson.contactPerson && (
        <ContactPerson
          heading={contactPerson.heading}
          branchColor={branchColor}
          name={contactPerson.contactPerson.title}
          extensionNumber={
            contactPerson.contactPerson.atspcontacts.contactData.extension
          }
          email={contactPerson.contactPerson.atspcontacts.contactData.email}
          portrait={
            contactPerson.contactPerson.atspcontacts.portrait
              ? contactPerson.contactPerson.atspcontacts.portrait.localFile
                  .childImageSharp.gatsbyImageData
              : undefined
          }
          altText={
            contactPerson.contactPerson.atspcontacts.portrait
              ? contactPerson.contactPerson.atspcontacts.portrait.altText
              : undefined
          }
          hasBackground
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query BrancheById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspBranchOptions {
        branchColor
      }
      atspSingleBranche {
        singleBrancheLogos {
          repeater {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
                }
              }
            }
          }
          heading
        }
        singleBrancheContactPerson {
          contactPerson {
            ... on WpKontakt {
              id
              title
              atspcontacts {
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        width: 2880
                        quality: 90
                      )
                    }
                  }
                }
                contactData {
                  email
                  extension
                  position
                }
              }
            }
          }
          heading
        }
        singleBrancheServices {
          heading
          intro
          wysiwyg
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
              }
            }
          }
        }
        singleBrancheTestimonials {
          repeater {
            text
            author {
              function
              name
              portrait {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      width: 2880
                      quality: 90
                    )
                  }
                }
              }
            }
          }
        }
        singleBrancheBestPractices {
          intro
          heading
          repeater {
            cardHeading
            cardLink {
              url
            }
            cardText
            customerLogo {
              localFile {
                publicURL
              }
              altText
            }
            cardHeaderImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
                }
              }
              altText
            }
          }
          button {
            title
            url
          }
        }
        singleBrancheSalesArguments {
          heading
          intro
          repeater {
            heading
            wysiwyg
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
        singleBrancheFocus {
          heading
          wysiwyg
        }
        singleBrancheIntro {
          heading
          wysiwyg
        }
        singleBrancheNews {
          heading
        }
        singleBrancheTabContent {
          tabs {
            tabs {
              title
              uuid
              wysiwyg
              downloads {
                title
                link {
                  link
                }
              }
              processes {
                uuid
              }
              contactPerson {
                ... on WpKontakt {
                  id
                  title
                  atspcontacts {
                    contactData {
                      email
                      extension
                    }
                    portrait {
                      altText
                      localFile {
                        url
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
            title
            tabStyle
          }
        }
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
    allWpNeuigkeit(sort: { order: DESC, fields: atspNews___newsDate }) {
      nodes {
        atspLanguage {
          doNotUseAsDefault
        }
        atspNews {
          newsDate
          newsImageTeaser {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        title
        slug
        link
        excerpt
        language {
          locale
        }
      }
    }
  }
`

export default TemplateSingleBranche

import React, { useState, useEffect, useRef } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Wysiwyg, Container, Heading, Button, Link } from 'src/components/Base'
import {
  cTabList,
  cParentWrapper,
  cParentMenuItem,
  cChildMenuItem,
  cActiveParentMenuItem,
  cActiveChildMenuItem,
  cMobileSelectWrapper,
  cDownloadLink,
  cChildTabList,
  cProcessLinks,
  cProcessLink,
  cSelect,
  cParentSelect,
  cChildSelect,
  cSelects,
  cDownloadsProcesses,
  cArrowTab,
  cContactCard,
} from './styles.module.scss'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import BaseCard from 'src/components/Cards/BaseCard'
import ContactCard from 'src/components/Cards/ContactCard'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

const TabControl = ({ tabs }) => {
  const tabWrapperRef = useRef(null)

  const [hashtagProceeded, setHashtagProceeded] = useState(false)
  const [showDropdowns, setShowDropdowns] = useState(false)
  const [parentTabs, setParentTabs] = useState([])
  const [childTabs, setChildTabs] = useState([])
  const [currentParentIndex, setCurrentParentIndex] = useState(0)
  const [currentChildIndex, setCurrentChildIndex] = useState(0)

  const staticData = useStaticQuery(graphql`
    query tabControlQuery {
      wp {
        themeGeneralSettings {
          atspSingleBranchenOptions {
            singleBrancheContactPersonHeading
            singleBrancheDownloadsHeading
            singleBrancheProcessesHeading
          }
        }
      }
    }
  `)

  useEffect(() => {
    tabs.tabs.map((parentTab, parentTabIndex) => {
      setParentTabs((prevParentTabs) => [
        ...prevParentTabs,
        { title: parentTab.title, index: parentTabIndex },
      ])
      if (parentTab.tabs && parentTab.tabs.length > 0) {
        parentTab.tabs.map((childTab, childTabIndex) => {
          setChildTabs((prevChildTabs) => [
            ...prevChildTabs,
            {
              ...childTab,
              parent: parentTabIndex,
              index: childTabIndex,
              style: parentTab.tabStyle,
            },
          ])
        })
      }
    })
  }, [])

  useEffect(() => {
    const onScroll = (e) => {
      const tabWrapperRect = tabWrapperRef.current.getBoundingClientRect()

      if (tabWrapperRect.top < 250) {
        setShowDropdowns(true)
      } else {
        setShowDropdowns(false)
      }

      if (-1 * tabWrapperRect.y >= tabWrapperRect.height) {
        setShowDropdowns(false)
      }
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    if (
      !hashtagProceeded &&
      parentTabs.length &&
      childTabs.length &&
      window.location.hash
    ) {
      const hash = window.location.hash.substring(1)
      let foundTab = childTabs.find((tab) => tab.uuid === hash)
      if (foundTab) {
        setCurrentChildIndex(foundTab.index)
        setCurrentParentIndex(foundTab.parent)
      }
      // Enhancement (this effect should only triggered be once and could be moved to a useEffect empty Array)
      setHashtagProceeded(true)
    }
  })

  const onParentTabChange = (index) => {
    setCurrentParentIndex(index)
    setCurrentChildIndex(0)
  }

  const onChildTabChange = (index) => {
    setCurrentChildIndex(index)
  }

  const renderMobileDropdown = () => {
    return (
      showDropdowns && (
        <div className={cMobileSelectWrapper}>
          <Container>
            <div className={cSelects}>
              <select
                value={currentParentIndex}
                onChange={(e) =>
                  setCurrentParentIndex(parseInt(e.target.value))
                }
                className={classNames(cSelect, cParentSelect)}
              >
                {parentTabs.map((parentTab) => (
                  <option key={uuid()} value={parentTab.index}>
                    {parentTab.title}
                  </option>
                ))}
              </select>
              <select
                value={currentChildIndex}
                onChange={(e) => setCurrentChildIndex(parseInt(e.target.value))}
                className={classNames(cSelect, cChildSelect)}
              >
                {childTabs.map((childTab) => {
                  if (childTab.parent === currentParentIndex) {
                    return (
                      <option key={uuid()} value={childTab.index}>
                        {childTab.title}
                      </option>
                    )
                  }
                })}
              </select>
            </div>
          </Container>
        </div>
      )
    )
  }

  const navigateToTabUuid = (uuid) => {
    let matchingChildTab = childTabs.find((childTab) => childTab.uuid === uuid)
    setCurrentParentIndex(matchingChildTab.parent)
    setCurrentChildIndex(matchingChildTab.index)
  }

  const getTabTitleByUuid = (uuid) => {
    if (uuid) {
      let matchingChildTab = childTabs.find(
        (childTab) => childTab.uuid === uuid
      )
      if (matchingChildTab) {
        return matchingChildTab.title
      }
    }
  }

  return (
    <div ref={tabWrapperRef}>
      {renderMobileDropdown()}
      <Tabs
        selectedTabClassName={cActiveParentMenuItem}
        selectedIndex={currentParentIndex}
        onSelect={onParentTabChange}
      >
        <TabList className={cTabList}>
          {parentTabs.map((parentTab) => (
            <Tab key={uuid()} className={cParentMenuItem}>
              {parentTab.title}
            </Tab>
          ))}
        </TabList>

        {parentTabs.map((parentTab) => (
          <TabPanel key={uuid()}>
            <div className={cParentWrapper}>
              <Tabs
                selectedTabClassName={cActiveChildMenuItem}
                selectedIndex={currentChildIndex}
                onSelect={onChildTabChange}
              >
                <TabList className={classNames(cTabList, cChildTabList)}>
                  {childTabs.map((childTab) => {
                    if (childTab.parent === currentParentIndex) {
                      const childTabClasses = classNames({
                        [cChildMenuItem]: true,
                        [cArrowTab]: childTab.style === 'arrows',
                      })

                      return (
                        <Tab key={uuid()} className={childTabClasses}>
                          {childTab.title}
                        </Tab>
                      )
                    }
                  })}
                </TabList>

                {childTabs.map((childTab) => {
                  if (childTab.parent === currentParentIndex) {
                    return (
                      <TabPanel key={uuid()}>
                        <Container>
                          <Wysiwyg>{childTab.wysiwyg}</Wysiwyg>
                        </Container>
                        {childTab.downloads || childTab.processes ? (
                          <SectionTwoColumns
                            className={cDownloadsProcesses}
                            hasBackground
                            centerMobile
                            contentLeft={
                              childTab.downloads[0].link && (
                                <>
                                  <Heading as="h3" size={3} color="dark">
                                    {
                                      staticData.wp.themeGeneralSettings
                                        .atspSingleBranchenOptions
                                        .singleBrancheDownloadsHeading
                                    }
                                  </Heading>
                                  <BaseCard
                                    bodyIsCentered
                                    headerHasNoPadding
                                    hasBorder
                                    isStretched
                                    body={childTab.downloads.map(
                                      ({ link, title }) => (
                                        <Button
                                          key={uuid()}
                                          as="a"
                                          target="_blank"
                                          href={link.link || 'https://atsp.com'}
                                          className={cDownloadLink}
                                          color="cta"
                                        >
                                          {title}
                                        </Button>
                                      )
                                    )}
                                  />
                                </>
                              )
                            }
                            contentRight={
                              childTab.processes && (
                                <>
                                  <Heading as="h3" size={3} color="dark">
                                    {
                                      staticData.wp.themeGeneralSettings
                                        .atspSingleBranchenOptions
                                        .singleBrancheProcessesHeading
                                    }
                                  </Heading>
                                  <BaseCard
                                    bodyIsCentered
                                    headerHasNoPadding
                                    hasBorder
                                    isStretched
                                    body={
                                      <div className={cProcessLinks}>
                                        {' '}
                                        {childTab.processes.length &&
                                          childTab.processes.map((item) => (
                                            <Link
                                              className={cProcessLink}
                                              to={`#${item.uuid}`}
                                              external
                                              hasArrows
                                              key={uuid()}
                                              onClick={() =>
                                                navigateToTabUuid(item.uuid)
                                              }
                                              color="cta"
                                            >
                                              {getTabTitleByUuid(item.uuid)}
                                            </Link>
                                          ))}
                                      </div>
                                    }
                                  />
                                </>
                              )
                            }
                          />
                        ) : null}
                        {childTab.contactPerson && (
                          <SectionTwoColumns
                            hasBackground
                            className={cDownloadsProcesses}
                            centerMobile
                            contentLeft={
                              <>
                                <Heading as="h3" size={3} color="dark">
                                  {
                                    staticData.wp.themeGeneralSettings
                                      .atspSingleBranchenOptions
                                      .singleBrancheContactPersonHeading
                                  }
                                </Heading>
                                <ContactCard
                                  className={cContactCard}
                                  portrait={
                                    childTab.contactPerson.atspcontacts.portrait
                                      ? childTab.contactPerson.atspcontacts
                                          .portrait.localFile.childImageSharp
                                          .gatsbyImageData
                                      : undefined
                                  }
                                  name={childTab.contactPerson.title}
                                  extensionNumber={
                                    childTab.contactPerson.atspcontacts
                                      .contactData.extension
                                  }
                                  email={
                                    childTab.contactPerson.atspcontacts
                                      .contactData.email
                                  }
                                  altText={
                                    childTab.contactPerson.atspcontacts.portrait
                                      ? childTab.contactPerson.atspcontacts
                                          .portrait.altText
                                      : undefined
                                  }
                                  hasBackground={false}
                                />
                              </>
                            }
                          />
                        )}
                      </TabPanel>
                    )
                  }
                })}
              </Tabs>
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  )
}

export default TabControl
